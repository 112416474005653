<script setup>
import axios from "axios";
import FooterContactCTA from '@/components/FooterContactCTA.vue'
</script>

<script>
export default {
    data() {
        return {
            homeCategories: [],
        }
    },
    mounted() {
        const StrapiBaseURL = 'https://strapi-121901-0.cloudclusters.net/api';
        var array = [];

        axios.get(`${StrapiBaseURL}/categories?populate=*`).then((resp) => {

            var categories = resp.data.data;

            console.log(categories)

            categories.forEach(z => {
                array.push({
                    name: z.attributes.name,
                    description: z.attributes.description,
                    homeText: z.attributes.homeText,
                    image: StrapiBaseURL.replace('/api', '') + z.attributes.image.data.attributes.url
                })
            });

            this.homeCategories = array;
        });
    }
}

</script>

<template>
    <div class="mx-auto max-w-2xl text-center mt-10">
        <h2 class="text-3xl tracking-tight text-gray-900 sm:text-4xl">Central de ajuda – Pepper</h2>
        <p class="mt-2 text-lg leading-8 text-gray-600">Como podemos te ajudar?</p>
    </div>

    <div class="mt-8 mb-12 flex flex-wrap items-center justify-center gap-8 px-6 lg:px-2">
        <!-- v-for -->
        <div v-for="link in homeCategories" :key="link" class="w-full lg:w-1/3 xl:w-1/4">
            <router-link :to="'/categories/' + link.name" class="border border-slate-200 h-full hover:border-indigo-400 cursor-pointer rounded-lg w-full flex flex-col justify-center gap-2 p-6 hover:shadow-lg transition-all duration-400 text-center">
                <div class="flex items-center px-8 py-4 mx-auto">
                    <div>
                        <img :src="link.image" class="w-full" />
                    </div>
                </div>
                <span class="text-xs block text-center text-gray-500">Artigos na categoria:
                    "<span class="capitalize font-semibold">{{ link.name }}</span>"
                </span>
                <h5>{{ link.homeText }}</h5>
            </router-link>
        </div>
    </div>
    <!-- Footer contact CTA -->
    <FooterContactCTA />
</template>