<template>
    <hr class="w-full mt-8 block" />

    <div class="max-w-4xl mx-auto pt-16 pb-8 px-6 lg:px-8 flex flex-col lg:flex-row gap-5 lg:justify-between lg:items-center">
        <p class="mr-auto mb-2 lg:mb-0 text-sm leading-6 text-gray-600"><b>Ainda tem dúvidas?</b> Envie uma mensagem para o nosso atendimento!</p>
        <div>
            <a href="/contato" class="rounded-md bg-red-600 px-4 py-2.5 text-sm font-semibold text-white hover:text-white hover:bg-red-500 focus-visible:outline-0">Falar com suporte</a>
        </div>
    </div>
</template>
  