<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import { vMaska } from "maska";

const open = ref(false);

// Máscaras inputs:
const maskPhone = {
    tokens: {
        N: {
            multiple: false,
            pattern: /[1-9]/
        }
    }
}
const maskEmail = {
    tokens: {
        a: {
            multiple: false,
            pattern: /^(?![@,#,!,$,%,¨,*,&,(,),+,=,[,{,},~,^,`,;,:,<,>])/,
            transform: chr => chr.toLowerCase()
        },
        b: {
            multiple: true,
            pattern: /^(?![#,!,$,%,¨,*,&,(,),+,=,[,{,},~,^,`,;,:,<,>])/,
            transform: chr => chr.toLowerCase()
        }
    }
}
</script>

<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            classes: {
                label: 'block text-sm font-semibold leading-6 text-slate-700',
                input: 'block w-full bg-white rounded-md py-2 px-3.5 text-gray-700 border border-gray-300 focus:outline-none placeholder:text-gray-400 focus:border-indigo-500 sm:text-sm sm:leading-6 transition duration-400',
                select: 'block w-full bg-white rounded-md border-0 py-2 pl-3 pr-10 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none placeholder:text-gray-400 focus:ring-indigo-500 sm:text-sm sm:leading-6 transition duration-400',
                invalidFeedback: 'text-xs text-red-500'
            },

            reason: undefined,
            contactReason: [
                {
                    id: 1,
                    option: 'Comprei pela Pepper e preciso de ajuda'
                },
                {
                    id: 2,
                    option: 'Sou produtor na Pepper e preciso de suporte'
                },
                {
                    id: 3,
                    option: 'Denunciar ou reportar um produto'
                },
                {
                    id: 4,
                    option: 'Quero mais informações sobre a Pepper'
                },
            ],

            // Validação - Nome
            nameIsValid: null,
            nameErrorMessage: '',
            name: '',

            // Validação - Email
            emailIsValid: null,
            emailErrorMessage: '',
            showEmailAutoSuggest: false,
            emailSuggest: [],
            email: '',

            // Validação - Phone
            phoneIsValid: null,
            phoneErrorMessage: '',
            phone: '',

            // Validação - Mensagem
            messageIsValid: null,
            messageErrorMessage: '',
            message: '',

            isLoadingButton: false,
            showInputCheckoutURL: false,

        }
    },
    methods: {

        previewFiles(event) {
            let files = event.target.files;
            for (let i = 0; i < files.length; i++) {

                var isTooBig = Boolean;

                (event.target.files[i].size > 2000000) ? isTooBig = true : isTooBig = false;
                console.log(isTooBig)
            }
        },

        onSelect(val) {
            this.showInputCheckoutURL = false;
            if (val == 'Denunciar ou reportar um produto') {
                this.showInputCheckoutURL = true;
            } else {
                this.showInputCheckoutURL = false
            }
        },

        // Validations:
        validateName(val) {
            this.nameIsValid = false;
            this.nameErrorMessage = 'Digite o seu nome completo.'
            if (val.length >= 0 && val.length >= 4) { this.nameIsValid = false; }
            if (val.length > 4) { this.nameIsValid = true; }
        },
        validateEmail(mail) {
            const EmailDomains = ['hotmail.com', 'gmail.com', 'terra.com.br', 'icloud.com', 'yahoo.com.br', 'outlook.com', 'live.com']
            if (!mail.includes('@')) this.showEmailAutoSuggest = false;
            if (mail.includes('@')) {
                this.showEmailAutoSuggest = true;
                const getLength = mail.split('@').length;
                const query = mail.split('@')[getLength - 1];
                const domain = RegExp(`.*${query.toLowerCase().split('').join('.*')}.*`);
                const matches = EmailDomains.filter(v => v.toLowerCase().match(domain));
                this.emailSuggest = matches;
            }
            this.emailIsValid = false;
            this.emailErrorMessage = 'Insira um e-mail válido.'
            // Regex e-mail:
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                this.emailIsValid = true;
                this.showEmailAutoSuggest = false;
            } else {
                this.emailIsValid = false;
                if (mail == '' || !mail) this.emailErrorMessage = 'Este campo é obrigatório.';
            }
        },
        selectEmailSuggest(suggested) {
            this.showEmailAutoSuggest = false;
            this.email = this.email.split('@')[0] + '@' + suggested;
            this.emailIsValid = true;
        },
        validatePhone(phone) {
            this.phoneIsValid = false;
            let phoneNumbers = phone.replace(/[\s.,-,/(,/)]*/igm, '');
            if (phoneNumbers.length >= 10) {
                this.phoneIsValid = true;
            } else {
                this.phoneIsValid = false;
                this.phoneErrorMessage = "Digite um telefone válido.";
            }
        },
        validateMessage(val) {
            this.messageIsValid = false;
            this.messageErrorMessage = 'Este campo é obrigatório.'
            if (val.length > 0) this.messageIsValid = true;
        },


        sendEmail() {
            this.isLoadingButton = true;

            if (this.nameIsValid == true && this.emailIsValid == true && this.phoneIsValid == true && this.messageIsValid == true) {

                emailjs.sendForm('service_4em7sqc', 'template_e9bkf8g', this.$refs.form, 'zwUQE8bFiVRm19hM9')
                    .then((result) => {

                        console.log(result);
                        this.isLoadingButton = false;

                        this.name = ''
                        this.email = ''
                        this.phone = ''
                        this.message = ''

                        alert('Mensagem enviada com sucesso!');
                    }, (error) => {
                        this.isLoadingButton = false;
                        alert('Falha ao enviar a sua mensagem. Verifique os campos preenchidos.');
                        console.log('FAILED...', error.text);
                    });
            }
        }
    }
}
</script>

<template>
    <div class="isolate pb-12 px-3 lg:px-6">

        <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">Fale com a Pepper</h2>
            <p class="mt-2 text-lg leading-8 text-gray-600">Se nossos artigos não te ajudaram, entre em contato e vamos responder assim que possível.</p>
        </div>

        <form ref="form" @submit.prevent="sendEmail" class="mx-auto mt-12 max-w-2xl">
            <div class="flex flex-col gap-y-5">

                <!-- Name -->
                <div class="flex flex-col gap-2">
                    <label :class="classes.label">Nome completo:</label>
                    <input
                        type="text"
                        inputmode="text"
                        required
                        name="name"
                        ref="name"
                        v-model="name"
                        autocomplete="name"
                        @input="validateName(name)"
                        :class="[{ 'border-red-600 focus:border-red-600': nameIsValid == false }, classes.input]" />
                    <div v-if="nameIsValid == false" :class="classes.invalidFeedback"> {{ nameErrorMessage }} </div>
                </div>

                <!-- Email -->
                <div class="flex flex-col gap-2">
                    <label :class="classes.label">Digite seu e-mail:</label>
                    <div class="relative">
                        <input
                            type="text"
                            required
                            name="email"
                            ref="email"
                            v-model="email"
                            v-maska:[maskEmail]
                            data-maska="aab"
                            autocomplete="email"
                            inputmode="email"
                            @keyup="validateEmail(email)"
                            @keyup.enter="selectEmailSuggest(emailSuggest[0])"
                            :class="[{ 'border-red-600 focus:border-red-600': emailIsValid == false && !showEmailAutoSuggest }, classes.input]" />

                        <!-- Email autosuggest -->
                        <transition appear>
                            <div v-if="showEmailAutoSuggest && emailSuggest.length > 0" class="w-full rounded-md bg-white border border-slate-400 shadow-lg absolute overflow-hidden top-100 z-40 mt-1">
                                <template v-for="sug in emailSuggest" :key="sug">
                                    <li @click.stop.prevent="selectEmailSuggest(sug)"
                                        :class="sug == emailSuggest[0] ? 'font-semibold bg-indigo-50 text-slate-700' : 'font-medium text-gray-600 hover:bg-indigo-100 hover:text-slate-700'"
                                        class="pr-3 pl-8 py-2.5 text-sm list-none cursor-pointer transition duration-600">
                                        {{ email.split('@')[0] + '@' + sug }}
                                    </li>
                                </template>
                            </div>
                        </transition>
                    </div>

                    <div v-if="emailIsValid == false" :class="classes.invalidFeedback"> {{ emailErrorMessage }} </div>
                </div>

                <!-- Phone -->
                <div class="flex flex-col gap-2">
                    <label :class="classes.label">Celular com WhatsApp:</label>
                    <input
                        type="text"
                        inputmode="tel"
                        required
                        name="phone"
                        ref="phone"
                        v-model="phone"
                        autocomplete="phone"
                        v-maska:[maskPhone]
                        data-maska="['(NN) N### ####', '(NN) N#### ####']"
                        @input="validatePhone(phone)"
                        :class="[{ 'border-red-600 focus:border-red-600': phoneIsValid == false && phone !== '' }, classes.input]" />
                    <div v-if="phoneIsValid == false && phone !== ''" :class="classes.invalidFeedback"> {{ phoneErrorMessage }} </div>
                </div>

                <!-- Contact reason -->
                <div class="flex flex-col gap-2">
                    <label :class="classes.label">Motivo do contato:</label>
                    <select
                        @change="onSelect(reason)"
                        :class="classes.select"
                        name="contact-reason"
                        v-model="reason"
                        ref="contact-reason"
                        required>
                        <option v-for="cont in contactReason" :key="cont" :value="cont.option">{{ cont.option }}</option>
                    </select>
                </div>

                <!-- v-if - checkout URL -->
                <div v-if="showInputCheckoutURL == true" class="flex flex-col gap-2">
                    <label :class="classes.label">URL do checkout do produto na Pepper:</label>
                    <input
                        type="text"
                        inputmode="text"
                        required
                        name="checkoutURL"
                        v-model="checkoutURL"
                        :class="[{ 'border-red-600 focus:border-red-600': checkoutURLIsValid == false }, classes.input]" />
                    <!-- <div v-if="phoneIsValid == false" :class="classes.invalidFeedback"> {{ phoneErrorMessage }} </div> -->
                </div>

                <div class="flex flex-col gap-2">
                    <label class="block cursor-pointer">
                        <span class="sr-only">Anexar arquivo(s)</span>
                        <input type="file" @change="previewFiles" name="myFile" multiple inputmode="none" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100 transition duration-400 cursor-pointer" />
                    </label>

                </div>

                <!-- Message -->
                <div class="flex flex-col gap-2">
                    <label :class="classes.label">Mensagem:</label>
                    <textarea
                        inputmode="text"
                        required
                        name="message"
                        ref="message"
                        v-model="message"
                        @input="validateMessage(message)"
                        :class="[{ 'border-red-600 focus:border-red-600': messageIsValid == false }, classes.input]"
                        rows="4" />
                    <div v-if="messageIsValid == false" :class="classes.invalidFeedback"> {{ messageErrorMessage }} </div>
                </div>

            </div>
            <div class="mt-10">
                <button
                    :class="{ 'opacity-60': isLoadingButton == true }"
                    :disabled="isLoadingButton == true"
                    class="flex items-center justify-center gap-2 w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-500 focus:outline-0">

                    <span v-if="isLoadingButton == false"> Enviar mensagem </span>

                    <svg v-if="isLoadingButton == true" class="animate-spin -ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span v-if="isLoadingButton == true">Enviando...</span>
                </button>
            </div>
        </form>
    </div>

    <hr class="w-full mt-8" />


    <!-- Modal send message -->
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-6 mx-auto text-center max-w-2xl">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white w-full px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon class="h-6 w-6 text-green-600" />
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-2">
                                        Mensagem enviada com sucesso.
                                    </DialogTitle>
                                    <p class="text-sm text-gray-500">
                                        Em breve você receberá uma resposta.
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-0" @click="open = false">
                                    Voltar
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>